export const rankingData = {
    name: {
        translate: true,
        key: 'widgets.ranking.name'
    },
    type: 'ranking',
    category: 'team',
    icon: ['fas', 'ranking-star'],
    defaultConfig: {
        w: 4,
        h: 8,
    },
    defaultContent: {
        mainTitle: {
            translate: true,
            key: 'widgets.ranking.defaultContent.mainTitle'
        },
        preTitle: {
            translate: true,
            key: 'widgets.ranking.defaultContent.preTitle'
        },
        startPosition: 1,
        limit: null,
        userSelect: 'all',
        users: [],
        groups: [],
        eventTypes: [],
        dataDisplay: 'sum',
        interval: 'all'
    },
    hasWidgetData: true,
    loadInitialData: true
}

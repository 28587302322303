export const currentData = {
    name: {
        translate: true,
        key: 'widgets.current.name'
    },
    type: 'current',
    category: 'data',
    icon: ['fas', 'chart-line'],
    defaultConfig: {
        w: 3,
        h: 2
    },
    defaultContent: {
        title: {
            translate: true,
            key: 'widgets.current.defaultTitle'
        },
        color: 'white',
        userSelect: 'all',
        users: [],
        groups: [],
        eventTypes: [],
        dataDisplay: 'sum',
        interval: 'all'
    },
    hasWidgetData: true,
    loadInitialData: true
}

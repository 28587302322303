export const latestData = {
    name: {
        translate: true,
        key: 'widgets.latest.name'
    },
    type: 'latest',
    category: 'data',
    icon: ['fas', 'calendar-alt'],
    defaultConfig: {
        w: 5,
        h: 9
    },
    defaultContent: {
        preTitle: {
            translate: true,
            key: 'widgets.latest.defaultContent.preTitle',
        },
        mainTitle: {
            translate: true,
            key: 'widgets.latest.defaultContent.mainTitle',
        },
        userSelect: 'all',
        users: [],
        groups: [],
        eventTypes: [],
        interval: 'all'
    },
    hasWidgetData: true,
    loadInitialData: true
}
